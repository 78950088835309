import React from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import styles from "./page-content.module.scss"

const ContentFold = ({
  hasBackground,
  title,
  children,
  className,
  isCentered,
  removePadding,
  titleSize,
  subtitle,
}) => (
  <section
    className={classNames(
      { [styles.padding3]: !removePadding },
      styles.paddingBottom,
      styles.container,
      { "has-background-light": hasBackground },
      className,
      { "p-0": removePadding }
    )}
  >
    {title && (
      <div
        className={classNames(
          "has-text-weight-bold has-text-primary py-1",
          { "has-text-centered": isCentered },
          { "is-size-2": !titleSize },
          { [`is-size-${titleSize}`]: titleSize }
        )}
      >
        {title}
      </div>
    )}
    {subtitle && <h1 className="subtitle is-5">{subtitle}</h1>}
    {children}
  </section>
)

ContentFold.propTypes = {
  hasBackground: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}

export default ContentFold
