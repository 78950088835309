import { graphql, useStaticQuery } from "gatsby"

const HEALTH_SURVEY_IMAGES_QUERY = graphql`
  {
    emailIcon: file(relativePath: { eq: "icons/email.png" }) {
      childImageSharp {
        fixed(width: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

const useHealthSurveyImages = () => {
  const data = useStaticQuery(HEALTH_SURVEY_IMAGES_QUERY)

  return data
}

export default useHealthSurveyImages
