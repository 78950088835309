import React from "react"

import SEO from "../../components/seo"
import Layout from "../../components/layout"
import HealthSurveyComplete from "../../components/HealthSurvey/HealthSurveyComplete"

export default props => (
  <Layout location={props.location}>
    <SEO
      title="Health Survey"
      keywords={[
        `novartis`,
        `medgrocer`,
        `pulse care`,
        `entresto`,
        `sacubitril-valsartan`,
      ]}
    />
    <HealthSurveyComplete />
  </Layout>
)
