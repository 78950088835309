import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import classNames from "classnames"

import ContentFold from "../page-content/content-fold"
import useHealthSurveyImages from "./hooks/useHealthSurveyImages"

import styles from "./utils/healthSurvey.module.scss"

const HealthSurveyComplete = () => {
  const images = useHealthSurveyImages()

  return (
    <ContentFold title="Thank You!" isCentered>
      <div className="columns is-centered mt-3">
        <div
          className={classNames(
            "column is-4 is-flex",
            styles["completed__icon"]
          )}
        >
          <Img fixed={images.emailIcon.childImageSharp.fixed} />
        </div>
      </div>
      <div className="buttons is-centered mt-5">
        <Link to="/" className="button is-primary is-medium">
          Finish
        </Link>
      </div>
    </ContentFold>
  )
}

export default HealthSurveyComplete
